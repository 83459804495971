var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "award-users",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c(
        "v-layout",
        {
          staticClass: "buzz-main-row",
          class: { compress: _vm.isHost },
          attrs: { shrink: "", row: "", "justify-center": "" },
        },
        [
          _c(
            "v-layout",
            {
              staticClass: "award-users__cnt",
              class: { clickable: _vm.isHost },
              attrs: { column: "", "justify-center": "" },
            },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    column: "",
                    "justify-center": "",
                    "mission-container": "",
                  },
                },
                [
                  _c(
                    "MissionContainer",
                    [
                      _vm.isPhotoAward &&
                      Array.isArray(_vm.awardCandidates) &&
                      _vm.awardCandidates[0] &&
                      _vm.awardCandidates[0].media &&
                      _vm.awardCandidates[0].media.src
                        ? [
                            _c(
                              "v-layout",
                              { attrs: { row: "" } },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    staticStyle: { position: "relative" },
                                    attrs: {
                                      "d-flex": "",
                                      xs6: "",
                                      "align-center": "",
                                      "justify-center": "",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "award-users__award-wining-image",
                                      attrs: {
                                        src: _vm.awardCandidates[0].media.src,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      "d-flex": "",
                                      "align-center": "",
                                      "ml-2": "",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("h2", [
                                        _vm._v("And the winner is..."),
                                      ]),
                                      _c(
                                        "h1",
                                        {
                                          staticClass:
                                            "award-users__winner-name",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.awardCandidates[0]
                                                  .firstname +
                                                  " " +
                                                  _vm.awardCandidates[0]
                                                    .lastname
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  column: "",
                                  "justify-center": "",
                                  "fill-height": "",
                                },
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { "d-flex": "" } },
                                  [
                                    _c("ResizableText", {
                                      attrs: { message: _vm.awardMessage },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.players, function (player) {
            return _c(
              "div",
              {
                key: "buzz-in-player-" + player.id + "-" + player.teamID,
                staticClass: "d-flex rtb-flex-wrap",
              },
              [
                _vm.isTrophyVisible
                  ? _c("img", {
                      staticClass: "award-users__trophy",
                      attrs: { src: _vm.TROPHY_IMAGE_URL },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "social-player-transition-wrap" },
                  [
                    _c("SocialPlayerCard", {
                      key: "buzz-in-" + player.id + "-" + player.teamID,
                      staticClass: "buzz-player-col",
                      attrs: { user: player },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { computed, onBeforeUnmount, ref, watch } from "@vue/composition-api"
import shuffle from "knuth-shuffle-seeded"

import { db } from "@/firebase"
import useStore from "@/use/useStore"
import Mission from "@shared/enums/Mission"
import MissionCollection from "@shared/MissionCollection"
import _ from "lodash"

export default function useAward() {
  const votes = ref([])
  const seed = Date.now()
  const awardMessage = ref("The Contenders")

  const { store } = useStore()
  const users = computed(() => store.getters["GameUsers/users"])
  const isHost = computed(() => store.getters["auth/isHost"])
  const mission = computed(() => store.getters.getCurrentMission)
  const missionPlays = computed(() => store.getters.missionPlaysArray)
  const missionId = computed(() => mission.value.id)
  const orgId = computed(() => store.getters.orgID)
  const roomId = computed(() => store?.getters.game?.id)
  const votesRef = computed(() =>
    orgId.value && roomId.value
      ? db.auxiliary().ref(`org/${orgId.value}/game/${roomId.value}/votes`)
      : undefined
  )
  const gameStatus = computed(() => store.getters.gameStatus)

  const isPhotoAward = computed(() =>
    [Mission.TakePhoto, Mission.Photo, Mission.PhotoBooth].includes(
      mission.value.behavior
    )
  )

  const instructions = computed(
    () => mission.value?.instructions || playStep.value?.instructions || ""
  )

  const playStep = computed(() =>
    MissionCollection.normalize(store.getters.missions).find(
      item => item.activityId === mission.value.activityId && item.play
    )
  )

  const awardCandidates = computed(() => {
    // turn an array into a string

    const votesByUserId = _.countBy(
      missionPlays.value,
      item => item.awardID || item.userID
    )

    const sortedUserIds = _.orderBy(
      Object.keys(votesByUserId),
      userId => votesByUserId[userId],
      "desc"
    )

    if (gameStatus.value.awardCandidates) {
      if (
        isPhotoAward.value &&
        Array.isArray(gameStatus.value.awardCandidates)
      ) {
        return gameStatus.value.awardCandidates
      }

      let contendersWithTotalVote = sortedUserIds
        .splice(0, 3)
        .map(id => users.value[id] ?? {})

      if (contendersWithTotalVote.length === 1) {
        // we don't want 1 player standing as contenders, let's add two more contenders
        const zeroVoteContenders = gameStatus.value.awardCandidates.filter(
          candidate => !votesByUserId[candidate.id]
        )
        contendersWithTotalVote = [
          ...contendersWithTotalVote,
          ...zeroVoteContenders.splice(0, 2)
        ]
      }

      return shuffle(contendersWithTotalVote, seed)
    } else {
      const makeUser = user => {
        return user || { firstname: "Not enough data", lastname: "" }
      }
      const makeResponse = (user, image) => {
        return [{ ...user, media: { type: "image", src: image } }]
      }

      let user
      if (isPhotoAward.value) {
        const winnedPlay = _.maxBy<{
          correct: { image: string }
          userID: string
        }>(missionPlays.value, "score")

        user = users.value[winnedPlay.userID]

        if (!user) {
          return []
        }

        return makeResponse(makeUser(user), winnedPlay?.correct?.image)
      } else {
        if (!sortedUserIds.length) return []

        user = users.value[sortedUserIds[0]]

        awardMessage.value = `
          <div style='font-size:25px;'>
            ${instructions.value}
          </div>
          <div style='font-size:40px; margin-top: 25px'>
            ${user?.firstname} ${user?.lastname}
          </div>
          `
      }

      const obj = makeUser(user)

      const found = missionPlays.value.find(
        play => play && play.userID === obj.id && play.correct?.image
      )

      return makeResponse(obj, found?.correct?.image)
    }
  })

  const isTrophyVisible = computed(() => awardCandidates.value?.length < 2)

  const players = computed(() =>
    awardCandidates.value.map(player => ({
      ...player,
      winer: isWinner(player.id)
    }))
  )

  function isWinner(userID) {
    return missionPlays.value.some(
      item =>
        (parseInt(item.score) > 10 || item.result) &&
        item.userID === userID &&
        item.show
    )
  }

  function onVotesUpdate(snapshot) {
    votes.value = Object.entries(snapshot.val() || {})
      .map(([id, vote]) => {
        return {
          // @ts-expect-error TODO
          ...vote,
          id
        }
      })
      .filter(
        vote =>
          vote.missionID === missionId.value ||
          vote?.activityId === mission.value?.activityId
      )
  }

  onBeforeUnmount(() => {
    votesRef.value?.off("value", onVotesUpdate)
  })

  watch(
    votesRef,
    (newValue, oldValue) => {
      oldValue?.off("value", onVotesUpdate)
      newValue?.on("value", onVotesUpdate)
    },
    { immediate: true }
  )

  return {
    isTrophyVisible,
    players,
    awardMessage,
    awardCandidates,
    isHost,
    instructions,
    isPhotoAward
  }
}
